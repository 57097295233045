<div class="background-cont">
  <div class="background">
    <div class="container" ref="container">
      <div class="main">
        <div class="spacer"></div>
        <router-link to="/" class="back mt-3 mb-0">back</router-link>
        <div class="rect mt-3">
          <div class="big-1">
            Yannick Spreen
          </div>
          <div class="big-4 row">
            <div class="col-5">
              📍 NYC
            </div>
            <div class="col-7 text-end">
              🎓 TUM M.Sc. Informatics 2020
            </div>
          </div>
        </div>
        <div class="rect">
          <div class="big-2">
            Contact
          </div>
          <div class="">
            <a href="//linkedin.com/in/yspreen/">LinkedIn</a>
            &ZeroWidthSpace;
            <a :href="email">E-Mail</a>
            &ZeroWidthSpace;
            <a href="//instagram.com/y_spreen/">Instagram</a>
          </div>
        </div>
        <div class="rect hand" @click="showExperience">
          <div class="big-2">
            Experience
            <div class="arrow">
              <i
                class="fas fa-caret-down"
                :class="experienceShown ? 'flip' : ''"
              ></i>
            </div>
          </div>
          <div style="padding-right: 30px">
            <i class="fas fa-mouse-pointer fa-flip-horizontal me-1" /> Click Any
            Of The Rectangles to Open Up a Quick Project Summary
          </div>
        </div>
        <div class="collapse" ref="accordionExperience">
          <listing
            v-for="(e, i) in experience"
            :key="i"
            :title="e.title"
            :summary="e.summary"
            :more="e.more"
          ></listing>
        </div>
        <div class="rect hand tight" @click="showProjects">
          <div class="big-2">
            Passion Projects
            <div class="arrow">
              <i
                class="fas fa-caret-down"
                :class="projectsShown ? 'flip' : ''"
              ></i>
            </div>
          </div>
          <div style="padding-right: 30px">
            <a href="//github.com/yspreen/">GitHub</a>
            &ZeroWidthSpace;
            <a href="//codepen.io/yspreen">CodePen</a>
          </div>
        </div>
        <div class="collapse" ref="accordionProjects">
          <listing
            v-for="(e, i) in projects"
            :key="i"
            :title="e.title"
            :summary="e.summary"
            :more="e.more"
          ></listing>
        </div>
      </div>
    </div>
  </div>
</div>
