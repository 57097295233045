export default {
  data() {
    return {
      shown: false
    };
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    summary: {
      type: String,
      default: ""
    },
    more: {
      type: String,
      default: ""
    }
  },
  computed: {
    dTitle() {
      return this.replace(this.title);
    },
    dSummary() {
      return this.replace(this.summary);
    },
    dMore() {
      return this.replace(this.more);
    },
    hasMore() {
      return this.more.trim().length && true;
      // let s = this.$slots.default;
      // if (!s.length) {
      //   return false;
      // }
      // s = s[0];
      // console.log(s);
      // return (s.text.trim().length || s.children || s.tag) && true;
    }
  },
  methods: {
    replace(s) {
      return s.replace(/\$DEL/g, '<span class="redacted">hidden</span>');
    },
    toggle() {
      if (!this.hasMore) {
        return;
      }
      new window.bootstrap.Collapse(this.$refs.accordion);
    },
    openLink() {
      window.open(this.link);
    }
  }
};
