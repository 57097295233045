<template>
  <!-- <router-view :key="$route.path" /> -->
  <router-view v-slot="{ Component }">
    <transition :name="transition" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<style lang="scss"></style>

<script>
export default {
  data() {
    return {
      transition: ""
    };
  },
  mounted() {
    setTimeout(() => {
      this.transition = "slide";
    }, 1);
  }
};
</script>
