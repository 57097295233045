export default {
  data() {
    return { works: true };
  },
  mounted() {
    let works = false;
    try {
      CSS.supports("( backdrop-filter: blur(1px) )") && (works = true);
    } catch {
      works = false;
    }
    const ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
      works = true;
    }
    if (!works) {
      console.warn(
        "This browser doesn't support blurred background filtering!"
      );
    }
    this.works = works;
  },
  methods: {
    clickHint() {
      window.open("//www.google.com/chrome/");
    }
  }
};
