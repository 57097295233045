<template>
  <Portfolio class="sl-right" />
</template>

<script>
// @ is an alias to /src
import Portfolio from "@/components/Portfolio/comp.vue";

export default {
  name: "Home",
  components: {
    Portfolio
  }
};
</script>
