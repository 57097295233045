export default {
  experience: [
    {
      title:
        'Founder & CPO – <a href="//getbubble.app" target="_blank">Bubble Video Commenting</a><br />Q1 2021 – Q3 2021',
      summary:
        "GCP, Kubernetes, Celery, Google CDN, FFMpeg, Django, Swift, AVKit, nginx, Redis, PostgreSQL",
      more: `While the world of content creation and consumption has developed drastically over the last few years, the comments-section has been largely untouched. Bubble envisions a world where everyone’s given a voice through short, round face-videos on any content. Comment a website, a TikTok video, or a picture you took on your last vacation and share it with the world.<br />
In just a couple of months, Yannick built a feature-complete v1.0 of a consumer social iOS application. As the leading full-stack engineer, Yannick built a Kubernetes micro-service architecture to process, render, and deliver video files globally.<br />
Through the use of many GCP technologies, latencies and performance were improved >15× by employing Google’s CDN services into the ingress configurations. The iOS application renders multi-layered videos in real time with Apple’s AVMutableComposition framework.`
    },
    {
      title:
        "iOS Lead – EU Digital COVID Certificate Apps<br />Q2 2021 – Q2 2021",
      summary:
        "EC Cryptography, CBOR Data, COSE Signatures, Swift, COVID-19, Certificate Pinning, QR Code Scanner",
      more: `During the COVID-19 pandemic, the EU decided to standardize digital vaccination certificates in the form of a mobile phone app. The goal of this project was to standardize development of all EU member countries by defining one standard, one protocol, and provide a working stack in form of ‘template’ repositories for all involved platforms.<br />
In early 2021, Yannick kicked off the open-source development of this application as the lead iOS developer. An open-source approach and ECC-encryption were key to the success of the multi-platform project. For all user-facing data, QR Codes were used and stored base45-encoded COSE data to make the graphical representation of vaccine certificates as compact as possible.<br />
The three iOS repositories Yannick created for the project can be found on <a href="//github.com/eu-digital-green-certificates/dgca-wallet-app-ios">GitHub</a>.
`
    },
    {
      title: "Founder & COO – School Night<br />Q4 2019 – Q1 2021",
      summary: "WebRTC, Kubernetes, Django, Swift, Redux, AWS",
      more: `Yannick had the fortunate opportunity to launch and grow his own company throughout 2020, which was based in New York City and focused on consumer social products. For their pre-seed investment, they successfully raised over $1M from international investors.
Yannick designed a software architecture around scaling real-time media streaming. The app for iOS devices was built in Swift.`
    },
    {
      title:
        "Data Science Engineer – Kutalla Activity Mining<br />Q4 2018 – Q4 2019",
      summary: "Vue.js, D3.js, Postgres, Django, C++, C#, .Net Core",
      more: `Kutalla is a TUM alumni start-up founded in 2018. Its mission statement is to detect, identify and eliminate inefficient processes from all layers of an organization. Most companies don’t have a good grasp on the time they spend and waste on inefficient tooling and manual & labour-intensive tasks in their organization. Kutalla takes state-of-the-art data science to aggregate and analyse activity data from entire companies to make qualitative statements about the processes happening in an organization.

Yannick engineered the platform which enables Kutalla to process and aggregate all customer data in a GDPR and DSGVO compliant manner. Once the pilot was complete and data started coming in, Yannick lead the data science engineering to find patterns and tool chains in company & department behaviour to identify and eliminate labour-intensive tasks.

Yannick designed a large-scale data aggregation architecture in SQL, as well as front-end and back-end software to gather data into Kutalla’s format.`
    },
    {
      title:
        "Full-Stack Engineer – Eventmatch iOS App & REST Server<br />Q2 2019 – Q3 2019",
      summary: "Swift, iOS, Django, REST, SQL, Stripe, Google Cloud Platform",
      more: `Eventmatch is a TUM start-up founded in 2017 aimed at bringing people together in the real world. In 2019, the app got a major overhaul and started with a fresh codebase on both iOS and Android.

Yannick lead the iOS and server-side engineering for the new version. Responsibilities included requirement analysis, architecture, full-stack development, and dev ops for a redesigned cloud deployment on GCP.`
    },
    {
      title:
        "Full-Stack Web Engineer – WIWI InfoScreens & other projects<br />Q3 2017 – Q3 2018",
      summary: "Node.js, Angular, REST, AWS, Django, Swift",
      more: `BrainTree is your consultant and service provider for the digital transformation of your company. We offer you software development at the highest level. Tailored to your needs, we develop individual software solutions that leave nothing to be desired. We always use future-proof technologies, adapted to your problem.<br />
Yannick was part of BrainTree’s engineering team for a year, contributing to all of BT’s consulting projects for that period. As a full-stack engineer, he mainly worked on Angular web projects with a Django backend, and NativeScript iOS projects using Node.js in the mobile ecosystem. All of these projects were deployed as scaling services in AWS’ cloud environment.`
    },
    {
      title:
        "Front-End Developer – Embedded Kiosk Touch Interface<br />Q3 2016",
      summary: "QML, QtQuick, C++, JS, MQTT, Linux, Raspberry Pi",
      more: `In its new 2016 research project, HARTING expanded the vending machine line up with a smart touchscreen interface. In this project, the kiosk shows a dynamic interface that’s optimized for the user interfacing with it. For example, a face detection API is used for rudimentary filtering, showing children only the items to purchase which they’re allowed to buy. The project was based on a Raspberry Pi integrated touchscreen system.

As lead developer for this project, Yannick designed an architecture to facilitate the needs of this dynamic interface. Yannick ensured an easily accessible UI which allows the user to select items to be dropped on the cash register’s conveyor belt. The UI is optimized and filtered according to facial features returned by Microsoft’s Face API.`
    },
    {
      title: "Front-End Developer – Dynamic QML Interfaces<br />Q3 2015",
      summary: "Qt5, QML, C++, Javascript, Raspberry Pi",
      more: `HARTING KGaA is a hidden champion, providing the entire world with industrial connectors, electrical and more. As a future-focused enterprise, HARTING puts a lot of resources in its R&D departments. In 2015, a new undertaking was started, to standardize the software component of all showcases for fairs and more. This new structure allows a dynamic assortment of new technology showcases to be controlled and configured from a single Android device.

Yannick’s role was to build the generic architecture to facilitate dynamic and changing UIs for all sorts of technologies. This framework is future-proof, and allows new technologies to be added with simple XML files. These files are dynamically loaded to create an ever-changing UI on an Android tablet. Yannick lead this project, contributing his experience in C++ and JS development.`
    }
  ],
  projects: [
    {
      title: "AdventOfCode – Solving 200+ Coding Puzzles<br />2018 – today",
      summary: "Python, Numpy",
      more: `Advent of Code is an annual event featuring Christmas-themed puzzles that are meant to be solved with code. Since 2015, tens of thousands of programmers have tackled the new puzzles that appear every day from December 1 through 25.<br />
      Being a completionist, Yannick has been working his way backwards through the six years of over 200 puzzles, solving all of them over time. All solutions are available on GitHub at <a href="//github.com/yspreen/adventofcode" target="_blank">yspreen/adventofcode</a>.`
    },
    {
      title: "Bio-No.de – Containers and HPC for Bio Data Science<br />2020",
      summary: "Kubernetes, Vue, Django, GCP, Dev Ops",
      more: `Bio-Node is a platform that makes the power and versatility of HPC cloud computing accessible to biological researchers. Yannick built a self-scaling Kubernetes-based system on GCP in his Master’s thesis at Rutgers. The system allows users to submit containerized tasks through a web-interface and makes use of a highly distributed computing cluster to execute these tasks.`
    },
    {
      title: "Four in a Rowwdc – Apple WWDC Scholarship Grantee<br />2019",
      summary: "Swift, Xcode Playground, SpriteKit",
      more: `For the WWDC 2019, Yannick created a small game using Apple’s SpriteKit. The four in a row implementation allows the user to play a friend, or fight a surprisingly clever AI. With this submission, Apple sponsored Yannick’s attendance in the developer conference in San Jose, CA.`
    },
    {
      title: "COVID Info Page – Crowd-Sourcing COVID Restrictions<br />2019",
      summary: "Vue.js, PWA, GitHub Pages, OpenStreetMap, OpenLayers",
      more: `On covid-info.page, you can contribute to a crowdsources database of COVID-19 restrictions. Yannick built the page to run on $0 hosting via GitHub Pages, and making use of b64 encoded self-contained URLs for submission. The site features a full polygon editor made with OpenLayers to allow users to draw the boundary of the area they're adding contributions to. When viewing restrictions, the page makes use of GPS data to show you the most relevant local information.`
    },
    {
      title: "knok – iOS Chat App<br />2019",
      summary: "iOS, Swift, WebSockets",
      more: `knok is a text messenger that connects people in the moment. As such, it only allows you to message friends that are currently using their phone. Turning off the screen and placing it in your pocket automatically turns knok in silent mode and you can no longer receive texts. Yannick made use of some esoteric combinations of iOS APIs to detect active usage of an iOS device to enable this magical texting experience.`
    },
    {
      title: "Dub – iOS Double Camera App<br />2019",
      summary: "iOS, Swift, Snapchat API, Metal",
      more: `In 2019, Apple announced a new developer API to access camera feeds of both the front- and back-facing camera at the same time. In a team of two, Yannick and his UX design partner built a simple app which took picture-in-picture photos and videos in high quality. The app made use of the Snapkit APIs for easy sharing, as well as the Apple Metal shaders for efficient processing of the video streams.`
    },
    {
      title: "Checkoutinator – Asset Tracking<br />2019",
      summary: "RFID, UHF, Raspberry Pi, Python, OPCUA",
      more: `Participating in the TUM Techchallenge, Yannick reverse-engineered the OPCUA protocol of a HARTING UHF reader to automate the checkout process of tools in a Makerspace. Every tool is equipped with an RFID tag to automate tracking and theft detection. Everything was implemented on a Raspberry Pi in Python.`
    },
    {
      title: "Aucuro – Harvesting Robot<br />2018",
      summary: "Cython, OpenCV, Robotics, REST API, Computer Vision",
      more: `Using (and hacking the use of) Franka Emika’s REST API, Yannick controlled a 6-axis robot-arm to aid in the harvest of cucumbers. The robot is equipped with a webcam, and will detect and harvest fresh pickled cucumbers. Implementation on Cython made use of OpenCV functionality to detect and locate cucumbers precisely.`
    },
    {
      title: "Double or Nothing – Chrome Extension<br />2018",
      summary: "Chrome Extension, JS, PayPal API",
      more: `For the 2018 Microsoft Creative Hack, Yannick built a Chrome extension to gamify the Amazon purchase experience. An added button allows you to add some fun to your online shopping. Instead of hitting the One-click-purchase button, the user can select the Double-or-Nothing button. For the price of one item, they will now either receive two – or none at all! Payment is handled through the PayPal API.`
    },
    {
      title: "Spotify Podcast Listener – Chrome Extension<br />2018",
      summary: "Chrome Extension, JS, Spotify API",
      more: `Yannick saw an opportunity in enhancing the listening experience for a Spotify podcast. The podcast contains breaks in which the user is encouraged to play some music. Yannick built a chrome extension that enhances the Spotify web player to allow timestamping of songs to be injected into the playback of the podcast. The library for these timestamps is user-extensible.`
    },

    {
      title: "GSpy – Plugin Platform for Dynamic Desktop UIs<br />2017",
      summary: "PyQt5, Windows Apps, Linux Apps",
      more: `New programming paradigms and developments don't always trickle down to scientific tool chains rapidly. In the TU BS Institute of Computer and Network Engineering, satellite components were still interfaced with through ancient programming interfaces. For his Bachelor's thesis, Yannick set out to proof that a python-based plugin platform was just as capable of doing the job, while allowing for more modern programming practices to take over.`
    },
    {
      title: "PiStorm – Decentralized AI for soccer-playing robots<br />2016",
      summary: "LeJOS, Java, Raspberry Pi",
      more: `For the 2016 Software Engineering Project at TU BS, Yannick developed a decentralized AI to remotely control soccer-playing Mindstroms robots. The team of five programmed Raspberry Pis to decode and analyse webcam tracking data to analyse the field and devise strategies for the robotic team of three players. All logic was built decentralized, running on one processor per player, making networking and communication an integral component of the project.`
    }
  ]
};
