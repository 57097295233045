import Listing from "../Listing/comp.vue";
import content from "../content";
export default {
  components: { Listing },
  name: "HelloWorld",
  props: {
    msg: String
  },
  data: () => {
    return {
      projectsShown: false,
      experienceShown: false,
      email: "bWFpbHRvOnlAc3ByZWVuLmNv",
      experience: content.experience,
      projects: content.projects
    };
  },
  methods: {
    covid() {
      window.open("//covid-info.page");
    },
    showProjects() {
      this.projectsShown = !this.projectsShown;
      new window.bootstrap.Collapse(this.$refs.accordionProjects);
    },
    showExperience() {
      this.experienceShown = !this.experienceShown;
      new window.bootstrap.Collapse(this.$refs.accordionExperience);
    }
  },
  created() {
    this.email = atob(this.email);
  }
};
