import { createRouter, createWebHashHistory } from "vue-router";
import CV from "../views/CV.vue";
import Index from "../views/Index.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Index
  },
  {
    path: "/CV",
    name: "CV",
    component: CV
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
