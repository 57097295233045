<template>
  <Landing class="sl-left" />
</template>

<script>
// @ is an alias to /src
import Landing from "@/components/Landing/comp.vue";

export default {
  name: "Home",
  components: {
    Landing
  }
};
</script>
