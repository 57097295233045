<div class="host rect in-1" :class="{ hand: hasMore }" @click="toggle">
  <div class="d-flex">
    <div class="expand big-3" v-html="dTitle" />
    <div v-if="hasMore">
      <i class="fas fa-info fa-fw" title="Click for more" />
    </div>
  </div>

  <div ref="accordion" class="collapse">
    <div class="collapse-body more pt-4 pb-2">
      <i class="fas fa-angle-right" />
      <div class="d-inline more">
        <span style="padding-left: 3px" v-html="dMore" />
      </div>
    </div>
  </div>
  <div class="hashs pt-3">
    <i class="fas fa-hashtag" /><span v-html="dSummary" />
  </div>
  <div class="indent-arrow">•</div>
</div>
