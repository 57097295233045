<div class="host">
  <div class="ball-cont" :class="{legacy: !works}">
    <div v-if="works" class="ball one" />
    <div v-if="works" class="ball two" />
    <div v-if="works" class="ball three" />
  </div>
  <div class="cont">
    <div>Fullstack Engineer</div>
    <div class="name">Nick Spreen</div>
    <div>Freelancer</div>
    <div>Creator</div>
    <div>Founder</div>
    <a href="https://bento.me/spreen" class="mt-1" target="_blank" >Portfolio ></a>
    <div class="hint" v-if="!works" @click="clickHint">
      Looks better on Chrome/ium and Safari!
    </div>
  </div>
</div>
